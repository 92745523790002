import React from 'react';
import {PopupWidget} from 'react-calendly';


const Calendly = () => {
  return (
    <div>
      <PopupWidget url="https://calendly.com/lucas-fonseca" />
    </div>
  );
};

export default Calendly;